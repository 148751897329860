body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.noscrollbar::-webkit-scrollbar {
  display: none;
}

.noscrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none; 
}

.chip {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mainContainer {
  padding: 10px;
}

.categoryHeadingContainer {
  padding: 10px;
  background-color: #cdcdcd
}

.itemContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 10px;
}
.imageContainer {
  flex: 3;

}

.informationContainer {
  flex: 6;
  flex-direction: column;
  padding-left: 10px;
}

.otherContainer {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: space-around;
  padding-right: 5px;
}

li, ul {
  list-style-type: none !important;
}